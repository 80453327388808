import React from 'react'

const ContainerWrapper = ({children,className,index=0}) => {
  return (
    <div className={`${index=== 0?"h-screen":"h-auto"} flex-col justify-center items-center lg:px-8 md:px-6 px-4 py-12 relative ${className}`}>
        {children}
    </div>
  )
}

export default ContainerWrapper